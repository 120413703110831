import { defineMessages } from "react-intl";

export default defineMessages({
  imprintTitle: {
    id: `imprintTitle`,
    defaultMessage: "Imprint"
  },
  imprintHeader: {
    id: `imprintHeader`,
    defaultMessage: "According to § 5 TMG:"
  },
  imprintAddress: {
    id: `imprintAddress`,
    defaultMessage: "SUPRA Foto-Elektronik-Vertriebs-GmbH \n" +
      "Denisstr. 28 A II\n" +
      "D-67663 Kaiserslautern\n"
  },
  imprintDirectorHeader: {
    id: `imprintDirectorHeader`,
    defaultMessage: "Managing Directors"
  },
  imprintDirectorText: {
    id: `imprintDirectorText`,
    defaultMessage: "Ralph Hennes"
  },
  imprintRegistryHeader: {
    id: `imprintRegistryHeader`,
    defaultMessage: "Registry Court"
  },
  imprintRegistryText: {
    id: `imprintRegistryText`,
    defaultMessage: "Amtsgericht Kaiserslautern\nHRB No.: 1560\n"
  },
  imprintVatHeader: {
    id: `imprintVatHeader`,
    defaultMessage: "Registry Court"
  },
  imprintVatText: {
    id: `imprintVatText`,
    defaultMessage: "according to §27 a Umsatzsteuergesetz:\nDE 148 637 374\n"
  },
  privacyTitle: {
    id: `privacyTitle`,
    defaultMessage: "Data protection"
  },
  privacy1Title: {
    id: `privacy1Title`,
    defaultMessage: "§ 1 General Information"
  },
  privacy1Text: {
    id: `privacy1Text`,
    defaultMessage: "Your personal data (f. ex. title, name, address, e-mail address, telephone number, bank details, credit card number) are processed by us only in accordance with the provisions of the German Data Protection Act and the data protection law of the European Union (EU). The following provisions inform you about the type, extent and purpose of the collection, processing and use of personal data. Our privacy statement relates only to our websites. If you are forwarded to other websites by links on our websites, please familiarise yourself in those websites with the respective handling of your data. Your personal data (f. ex. title, name, address, e-mail address, telephone number, bank details, credit card number) are processed by us only in accordance with the provisions of the German Data Protection Act and the data protection law of the European Union (EU). The following provisions inform you about the type, extent and purpose of the collection, processing and use of personal data. Our privacy statement relates only to our websites. If you are forwarded to other websites by links on our websites, please familiarise yourself in those websites with the respective handling of your data."
  },
  privacy2Title: {
    id: `privacy2Title`,
    defaultMessage: "§ 2 Information, Deletion, Blocking"
  },
  privacy2Text: {
    id: `privacy2Text`,
    defaultMessage: "You have the right at any time to obtain information about your stored personal data, their origin and recipients and the purpose of the data processing, as well as a right to correction, blocking or deletion of this data, free of charge. To this, and for further information on personal data you can contact us at any time at the address provided in the imprint."
  },
  privacy3Title: {
    id: `privacy3Title`,
    defaultMessage: "§ 3 Information on the use of cookies"
  },
  privacy3Text: {
    id: `privacy3Text`,
    defaultMessage: "(1) Purpose of processing\n" +
      "This website uses cookies that are required for technical purposes. These are small text files, which are not stored permanently in or by your internet browser on your computer system. These cookies enable, for example, the placing of several products in a shopping basket.\n" +
      "Other cookies remain permanently and recognise your browser at the following visit. These cookies enable you to permanently store, for example, your passwords for a customer account.\n" +
      "(2) Legal basis\n" +
      "The legal basis for the processing is Art. 6 para. 1 a) GDPR.\n" +
      "Where appropriate, you have expressly provided the following consent:\n" +
      "Our website uses tracking cookies to enable us to better understand the use of the site by visitors and to optimise our website. By using this website, you agree to this.\n" +
      "(3) Period of storage\n" +
      "The cookies that are required for technical reasons are usually deleted when the browser is closed. Cookies, which are permanently stored have a life span that varies from a few minutes to several years.\n" +
      "(4) Right of revocation\n" +
      "If you do not wish that these cookies are stored, please deactivate the acceptance of these cookies in your internet browser. This can, however, result in a restriction of the functionality of our website.\n" +
      "You can revoke your consent to the permanent storage at any time by deleting the stored cookies via your browser.\n"
  },
  privacy4Title: {
    id: `privacy4Title`,
    defaultMessage: "§ 4 Rights of the person concerned"
  },
  privacy4Text: {
    id: `privacy4Text`,
    defaultMessage: "If your personal data is processed, you are a person concerned within the meaning of GDPR, and you have the following rights with respect to us:\n" +
      "1. Right to information\n" +
      "You can request confirmation from us as to whether we are processing personal data relating to you.\n" +
      "If we are processing such data, you can request the following information from us:\n" +
      "(1) the purposes of the processing of the personal data;\n" +
      "(2) the categories of personal data you are processing;\n" +
      "(3) the recipients or categories of recipients to which the personal data relating to you has been disclosed or is yet to be disclosed;\n" +
      "(4) the intended period of storage of the personal data related to you or, if it is not possible to provide specific details regarding this, the criteria for determining the period of storage;\n" +
      "(5) the existence of a right of correction or deletion of the personal data relating to you, or of limitation of processing by the Responsible Party, or of a right of objection against this processing;\n" +
      "(6) the existence of a right of appeal to a supervisory authority;\n" +
      "(7) if the personal data are not collected from the Person concerned, all available information on the origins of the data;\n" +
      "(8) the existence of an automated decision making including profiling in accordance with Art. 22, para. 1 and 4 GDPR and, at least in these cases, meaningful information about the logic applied as well as the implications and envisaged effects of such processing on the Person concerned.\n" +
      "You have the right to request information on whether the personal data relating to you is transmitted to a third country or an international organisation. In this connection, you may request information about the appropriate guarantees in accordance with Art. 46 GDPR, in connection with the transmission.\n" +
      "2. Right to correction\n" +
      "You have a right to request that we correct and/or complete the processed personal data relating to you, provided that such data is incorrect or incomplete. We are obliged to carry out such correction immediately.\n" +
      "3. Right to limitation of processing\n" +
      "Subject to the following conditions, you may request that we restrict the processing of the personal data relating to you:\n" +
      "(1) if you are disputing the personal data relating to you for a period of time, which enables us to verify if the personal data is correct;\n" +
      "(2) if the processing is unlawful, and you object the deletion of the personal data and instead request the restriction of the use of the personal data;\n" +
      "(3) we no longer require the personal data for the purposes of processing, however, such data is required by yourself for the assertion, exercise or defence of legal claims, or\n" +
      "(4) you have objected to the processing in accordance with Ar. 21, para. 1 GDPR, and it has not yet been determined if our legitimate interests outweigh your interests.\n" +
      "If processing of the personal data relating to you has been restricted, then this data may only be processed, apart from its storage, with your permission or for the assertion, exercise or defence of legal claims, or for the protection of the rights of another natural or legal person, or for reasons of a significant public interest of the EU or a member state.\n" +
      "If processing has been restricted in accordance with the above-mentioned conditions, we will notify you before the restriction is lifted.\n" +
      "4. Right to deletion\n" +
      "a) Duty to delete\n" +
      "You can request that we immediately delete the personal data relating to you, and we have a duty to delete such data immediately, if one of the following reasons applies:\n" +
      "(1) The personal information relating to you is no longer required for the purposes for which it was collected or otherwise processed.\n" +
      "(2) You are revoking your consent, on which the processing in accordance with Art. 6, para. 1 a or Art. 9, para. 2 a GDPR was based, and there is no other legal basis for processing.\n" +
      "(3) You are objecting to the processing in accordance with Art. 21, para. 1 GDPR, and there are no prevailing legitimate reasons for the processing, or you are objecting to the processing in accordance with Art. 21, para. 2 GDPR.\n" +
      "(4) The personal data relating to you was processed unlawfully.\n" +
      "(5) The deletion of the personal data relating to you is required to fulfil a legal obligation of the pursuant to EU Law or of the Law of the member states which governs us.\n" +
      "(6) The personal data relating to you was collected in relation to services offered by the information society, in accordance with Art. 8, para. 1 GDPR.\n" +
      "b) Information disclosed to third parties\n" +
      "If we have published the personal data relating to you, and we have a duty to delete such data in accordance with Art. 17, para. 1 GDPR, we will, having regard to the technology available and the cost of implementation, take reasonable measures, including measures of a technical nature, to inform parties responsible for the data processing, who process the personal data, that you as a Person concerned have requested the deletion of all links to these personal data or of copies or replications of these personal data.\n" +
      "c) Exceptions\n" +
      "The right to deletion does not exist where the processing is required\n" +
      "(1) for the exercise of the right to freedom of expression and information;\n" +
      "(2) to fulfil a legal obligation which requires the processing in accordance with the EU Law or the member states which governs us, or the performance of a task carried out in the public interest or in the exercise of official authority assigned to us;\n" +
      "(3) for reasons of public interests in the area of public health in accordance with Art. 9, para. 2 h and i as well as Art. 9, para. 3 GDPR;\n" +
      "(4) for the purposes of archiving that is in the public interest, scientific or historical research or for statistical purposes in accordance with Art. 89, para. 1 GDPR, where it is anticipated that the right mentioned under section a) renders the realisation of the objectives of this processing impossible or has a significant adverse effect, or\n" +
      "(5) for the assertion, exercise or defence of legal claims.\n" +
      "5. Right to correction\n" +
      "If you have asserted the right to correction, deletion or restriction of procession against us, we have a duty to notify all recipients to which the personal data relating to you has been disclosed, of this correction or deletion of the data or restriction of processing, unless this is proven to be impossible or subject to disproportionate expenses.\n" +
      "You have a right to request that we inform you about these recipients.\n" +
      "6. Right to data portability\n" +
      "You have the right to receive the personal data that you have provided us in a structured, common and machine-readable format; and you have the right to transfer this data to another person without hindrance from us, provided the processing is based on your consent or a contract and processing is done by us using automated procedures. You also have the right to transmit such data to another responsible party without any obstacles, provided that\n" +
      "(1) the processing is based on consent in accordance with Art. 6, para. 1 a GDPR or Art. 9, para 2 a GDPR or on a contract in accordance with Art. 6, para. 1 b GDPR and\n" +
      "(2) the processing is carried out by means of automated procedures.\n" +
      "When exercising this right, you also have the right to request that the personal data relating to you is transmitted directly by us to another Responsible party, insofar as this is technically feasible. The freedoms and rights of other persons must not be adversely affected by this.\n" +
      "The right to data portability does not apply to the processing of personal data required for the performance of a task carried out in the public interest or in the exercise of official authority assigned us.\n" +
      "7. Right of Objection\n" +
      "You have the right, at any time, to object to the processing of the personal data concerning you pursuant to Article 6, para. 1 e or f GDPR for reasons arising from your particular situation; this also applies to profiling based on these provisions.\n" +
      "We will then no longer process the personal data relating to you, unless we can demonstrate compelling legitimate grounds for processing that outweigh your interests, rights and freedoms, or the processing is for the purpose of asserting, exercising or defending legal claims.\n" +
      "If the personal data relating to you is processed for the purpose of direct advertising, you have the right to object at any time to the processing of your personal data for the purposes of such advertising; this also applies to profiling insofar as it is associated with such direct advertising.\n" +
      "If you object to the processing for purposes of direct advertising, the personal data related to you will no longer be processed for these purposes.\n" +
      "Regardless of Directive 2002/58/EU, you have the option, in the context of the use of information society services, to exercise your right to object through automated procedures that use technical specifications.\n" +
      "8. Right to revoke the consent under data protection law\n" +
      "You have the right to revoke your consent under data protection law at any time. The revocation of your consent does not affect the legality of the processing that was carried out on the basis of the consent prior to the revocation.\n" +
      "9. Automated decisions in Individual Cases including Profiling\n" +
      "You have the right not to be subjected to a decision that is exclusively based on automated processing, including profiling, where such decision has a legal effect or a similar significant adverse effect on you. This does not apply if the decision\n" +
      "(1) is required for the conclusion or performance of a contract between you and us,\n" +
      "(2) is permissible based on the legal provisions of the EU or the member states which governs us, and these legal provisions contain reasonable measures to safeguard the rights and freedoms as well as your legitimate interests, or\n" +
      "(3) is made with your express consent.\n" +
      "However, such decisions must not be based on special categories of personal data in accordance with Art. 9, para. 1 GDPR, if Art. 9, para. 2 a or g does not apply and reasonable measures to protect the rights and freedoms as well as your legitimate interests have been taken.\n" +
      "With regard to the cases mentioned in (1) and (3), we are taking reasonable measures to safeguard the rights and freedoms as well as your legitimate interests.\n" +
      "10. Right of Appeal to a Supervisory Authority\n" +
      "In addition, regardless of another administrative or judicial remedy, you have the right to submit an appeal to a supervisory authority, in particular in the member state of your place of stay, your workplace or the place of the suspected breach, if you are of the opinion that the processing of the personal data relating to you is in breach of GDPR.\n" +
      "The supervisory authority, to which the appeal has been submitted, informs the appellant of the status and the outcomes of the appeal, including the option of a judicial remedy in accordance with Art. 78 GDPR.\n"
  },
  privacyContactTitle: {
    id: `privacyContactTitle`,
    defaultMessage: "Contact Data Privacy Officer"
  },
  privacyContactText: {
    id: `privacyContactText`,
    defaultMessage: "QFU Christian Kehrt, Gesellschaft für Managementsysteme mbH\n" +
      "Europaallee 20\n" +
      "67657 Kaiserslautern\n" +
      "Deutschland\n" +
      "E-Mail info@qfu-gmbh.de\n" +
      "http://www.qfu-gmbh.de\n"
  }
});
